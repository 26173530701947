@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: 'Inter';
    font-display: fallback;
    src: url(../../public/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf) format('OpenType');
}
@font-face {
  font-family: 'Inter Italic';
  src: url(../../public/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf) format('OpenType');
  font-style: italic;
}

body {
  /* Disable responsiveness until we can address making the entire app mobile-friendly */
  min-width: 1440px;
}

.card-border {
    border-radius: 15px;
    overflow: hidden;
    perspective: 1px;
}
.line-break {
  white-space: pre-line;
}

.hover-state:hover .icon-hover-text-state{
  color: rgba(231, 67, 21, 1)
}

.hover-state:hover .text-hover-state {
  color: rgba(22, 40, 89, 1);
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.loader {
    /* margin: 0 0 0 10px; */
    font-size: 10px;
    display: inline-block;
    /* vertical-align: bottom;
    position: fixed; */
    top: 50%;
    left: 50%;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(33, 33, 33, 0.2);
    border-right: 0.5em solid rgba(33, 33, 33, 0.2);
    border-bottom: 0.5em solid rgba(33, 33, 33, 0.2);
    border-left: 0.5em solid rgba(33, 33, 33, 0.8);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}